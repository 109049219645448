<template>
    <div>
    <div class="w-full mt-5">
        <div class="w-full flex py-6 px-12 gap-6 rounded-xl" style="background-color: #f6f6f6">
            <div class="flex flex-grow">
                <div class="flex flex-col w-3/4 justify-center gap-2">
                    <p class="text-xl font-bold">
                        Create and organize employee document folders for easy access.
                    </p>
                    <p class="text-sm">
                        Employees can upload, download, and replace personal documents from their ESS console.
                    </p>
                </div>
                <div class="flex w-1/4 justify-end">
                    <div class="flex flex-col justify-center">
                    <img
                        src="@/assets/images/folder_open.png"
                        style="width: 100px; height: 100px"
                    />
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full">
            <loader v-if="isloadingSummary && isloadingEmployees" size="xxs" :loader-image="false" />
            <div v-else class="w-full">
                <div class="w-full flex flex-row justify-between gap-3 my-8">
                    <Card class="card flex flex-row justify-start items-center gap-3 p-3">
                        <div>
                            <icon icon-name="solar-user-icon" size="xsm" />
                        </div>
                        <div>
                            <p class="text-base font-semibold text-romanSilver">Employees</p>
                            <p class="text-sm text-jet font-semibold">{{ employeesCount }}</p>
                        </div>
                    </Card>   
                    <Card class="card flex flex-row justify-start items-center gap-3 p-3">
                        <div>
                            <icon icon-name="document" size="xsm" />
                        </div>
                        <div>
                            <p class="text-base font-semibold text-romanSilver">Folders</p>
                            <p class="text-sm text-jet font-semibold">{{ foldersCount }}</p>
                        </div>
                    </Card>  
                    <Card class="card flex flex-row justify-start items-center gap-3 p-3">
                        <div>
                            <img
                                src="@/assets/images/files-multi.png"
                                style="width: 24px; height: 24px"
                            />
                        </div>
                        <div>
                            <p class="text-base font-semibold text-romanSilver">Files</p>
                            <p class="text-sm text-jet font-semibold">{{ filesCount }}</p>
                        </div>
                    </Card>  
                </div> 

                <div class="w-full flex justify-start items-center gap-5 mb-4">
                    <div @click="$router.push({ name: 'EmpRecycleBin' })"
                        class="flex items-center gap-2 text-darkPurple cursor-pointer text-sm font-normal border-r  border-borderGrey pr-5"
                    >
                        <Icon
                            icon-name="delete"
                            class="text-flame"
                            size="xs"
                        /> Delete
                    </div>
                    <div @click="$router.push({ name: 'StorageSettings'  })" 
                    class="flex items-center gap-2 text-darkPurple cursor-pointer text-sm font-normal border-r  border-borderGrey pr-5"
                    >
                        <Icon
                        icon-name="quota"
                        class="text-flame"
                        size="xs"
                        /> View Quota
                        </div>
                    <div @click="$router.push({ name: 'Profile' })" 
                    class="flex items-center gap-2 text-darkPurple cursor-pointer text-sm font-normal border-r  border-borderGrey pr-5"
                    >
                        <Icon
                        icon-name="upgrade"
                        class="text-flame"
                        size="xs"
                        /> Upgrade
                    </div>
                    <div @click="$router.push({ name: 'Documents' })" 
                    class="flex items-center gap-2 text-darkPurple cursor-pointer text-sm font-normal pr-5"
                    >
                        <Icon
                        icon-name="settings"
                        class="text-romanSilver"
                        size="xs"
                        /> Settings
                    </div>
                </div>

                <Card class="p-5">
                    <template v-slot:footer>
                        <card-footer
                            @reload="reload()"
                            @sortType="handleSort($event)"
                            @option="handleOptionSelect($event)"
                            @searchResult="handleSearch($event)"
                            @actionModal="viewFilterTable($event)"
                            search-placeholder="Search"
                            :show-sort-filter="false"
                        />
                    </template>
                </Card>

                <div class="line" v-if="allEmployees.length > 0"/>

                <sTable
                    :headers="employeesHeader"
                    :items="employees"
                    style="width: 100%"
                    :loading="isloadingEmployees"
                    aria-label="employee table"
                    class="w-full"
                    id="printMe"
                    :pagination-list="allEmployeesMeta"
                    @page="handlePage($event)"
                    @itemsPerPage="handleItemsPerPage($event)"
                    page-sync
                    :has-number="false"
                    :has-checkbox="true"
                    v-if="allEmployees.length > 0"
                >
                    <template v-slot:item="{ item }">
                        <template v-if="item.fname">
                            <div class="flex flex-row items-center">
                                <template>
                                    <div>
                                        <img v-if="item.data.photo" :src="item.data.photo" alt="profile pic" class="w-8 h-8 rounded" />
                                        <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                                            {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                                        </div>
                                    </div>
                                </template>
                                <div class="ml-2 flex flex-col justify-between">
                                    <span class="font-bold text-sm text-darkPurple">
                                        {{ item.data.fname + ' ' + item.data.lname }}
                                    </span>
                                    <span class="font-black text-carrotOrange uppercase text-10">
                                        {{ item.data.orgFunction ? item.data.orgFunction.name : '-' }}
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="item.numberOfFolders">
                            <div class="flex flex-row items-center">
                                <template>
                                    <div>
                                        <icon icon-name="icon-folder" size="s" class="w-8 h-8 rounded text-romanSilver"/>
                                    </div>
                                </template>
                                <div class="ml-2 text-sm flex flex-col justify-between">
                                    <span class="font-bold text-jet">
                                        {{ item.data.numberOfFolders }}
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="item.numberOfFiles">
                            <div class="">
                                <p class="text-sm font-normal text-jet flex flex-col justify-between">
                                    {{ item.data.numberOfFiles }} files
                                </p>
                                <p class="font-bold text-xs text-blueYonder">
                                    {{ kbToSize(item.data.totalSize) }}
                                </p>
                            </div>
                        </template>
                        <span v-else-if="item.userId" class="text-right">
                            <Menu left top="-150" margin="24" class="my-2 p-0">
                                <template v-slot:title>
                                    <icon icon-name="more_icon" size="xs" />
                                </template>
                                <div class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2">
                                    <div class="w-full" 
                                        @click="$router.push({
                                                name: 'ViewEmployeeDocs',
                                                params: { id: item.data.userId }
                                            })"
                                    >
                                        <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                                            <Icon icon-name="icon-eye" class-name="text-blueCrayola" size="xs" />
                                            <p class="font-normal text-sm text-darkPurple">View  Employee</p>
                                        </div>
                                    </div>
                                    <div class="w-full" @click="actions('upload-folder', item.data)">
                                        <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                                            <Icon icon-name="upload-icon" class-name="text-carrotOrange" size="xs" />
                                            <p class="font-normal text-sm text-darkPurple">Upload Folder</p>
                                        </div>
                                    </div>
                                    <div class="w-full" @click="actions('download', item.data)">
                                        <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                                            <Icon icon-name="download" class-name="text-flame" size="xs" />
                                            <p class="font-normal text-sm text-darkPurple">Download Folder(s)</p>
                                        </div>
                                    </div>
                                </div>
                            </Menu>
                        </span>
                    </template>
                </sTable>

                <div class="w-full h-64 flex flex-col justify-center items-center gap-5" v-if="employees.length === 0">
                    <img class="wfull" :src="require('@/assets/images/emptyFolders.svg')" alt="not_found">
                    <p class="">There are no employees in your account yet.</p>
                </div>
            </div>
        </div>
    </div>

    <UploadFolder
      v-if="isUploadFolder"
      @close="isUploadFolder = false"
      :folders="folders"
      :setPermission="false"
    />
    <DownloadFolder v-if="openDownloadFolder" 
       @close="openDownloadFolder = false" 
      :item="selectedItem"
    /> 
    </div>    
</template>
  
<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import STable from "@scelloo/cloudenly-ui/src/components/table";
    
    export default {
        name: "Employees",
        components: {
            Tab,
            STable,
            Card: () => import('@/components/Card'),
            CardFooter: () => import("@/components/CardFooter"),
            Menu: () => import("@/components/Menu"),
            DownloadFolder: () => import("@/components/documents/DownloadFolder"),
            UploadFolder: () =>  import("@/components/documents/UploadFolder")
        },
        data() {
            return {
                isloadingEmployees: false,
                isloadingSummary: false,
                allEmployees: [],
                allFolders: [],
                totalEmployees: 0,
                totalFiles: 0,
                totalFolders: 0,
                sortType: null,
                itemsPerPage: null,
                numberOfPage: null,
                searchWord: null,
                allEmployeesMeta: {},
                isUploadFolder: false,
                openDownloadFolder: false,
                selectedItem: null
            };
        },
        computed: {
            employeesHeader(){ 
                return [
                    { title: "Owner", value: "fname" },
                    { title: "Folders", value: "numberOfFolders" },
                    { title: "Files", value: "numberOfFiles" },
                    { title: "", value: "userId", image: true }
                ];
            },
            employeesCount() {
                return this.totalEmployees;
            },
            foldersCount() {
                return this.totalFolders;
            },
            filesCount() {
                return this.totalFiles;
            },
            employees() {
                return this.allEmployees
            },
            folders() {
                return this.allFolders;
            },
        },
        methods: {
            actions(type, item) {
                this.selectedItem = item;
                switch (type) {
                    case 'upload-folder':
                        this.isUploadFolder = true;
                    break;
                    case 'download':
                        this.openDownloadFolder = true;
                    break;

                    default:
                    break;
                }
            },
            queryUpdate() {
                const pageNumber = this.numberOfPage ? 
                    `?page=${this.numberOfPage}` : `?page=${this.allEmployeesMeta.page}`;
                this.getAllEmployees(pageNumber);
            },
            handleSort(sortType) {
                this.sortType = sortType;
                this.queryUpdate();
            },
            handleItemsPerPage(itemNo) {
                this.itemsPerPage = itemNo;
                this.queryUpdate();
            },
            handlePage(pageNumber) {
                this.numberOfPage = pageNumber;
                this.queryUpdate();
            },
            handleSearch(searchWord) {
                if (searchWord) {
                    this.searchWord = searchWord;
                    this.queryUpdate();
                } else {
                    this.searchWord = null;
                    this.queryUpdate();
                }
            },
            handleItemsPerPage(itemNo) {
                this.itemsPerPage = itemNo;
                this.queryUpdate();
            },
            handlePage(pageNumber) {
                this.numberOfPage = pageNumber;
                this.queryUpdate();
            },
            async getAllEmployees(params=""){
                this.isloadingEmployees = true;
                try {
                    const { data } = await this.$_getAllEmployees(this.$orgId, params);
                    this.allEmployees = data.employees;
                    this.allEmployeesMeta = data.meta;
                    this.isloadingEmployees = false;
                } catch (error) {
                    this.isloadingEmployees = false;
                }
            },
            async getEmployeesSummary(){
                this.isloadingSummary = true;
                try {
                    const { data } = await this.$_getEmployeesSummary(this.$orgId);
                    this.totalEmployees = data.summary.numberOfEmployees;
                    this.totalFolders = data.summary.numberOfFolders;
                    this.totalFiles = data.summary.numberOfFiles;
                    this.isloadingSummary = false;
                } catch (error) {
                    this.isloadingSummary = false;
                }
            },
            async getAllEmployeesFolders(){
                const { data } = await this.$_getAllEmployeesFolders(this.$orgId);
                this.allFolders = data.folders === 0 ? [] : data.folders;
            },
            kbToSize(kb) {   
                const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
                if (kb === 0) return '0 KB';
                if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; // Converted small KB to Bytes
                    const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
                    const sizeIndex = Math.min(i, sizes.length - 1);
                if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
                return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
            }
        },
        async mounted() {   
            await this.getEmployeesSummary()
            await this.getAllEmployees("")
            await this.getAllEmployeesFolders()
        }
    };
</script>

<style scoped>
.card{
    width: calc(100% / 3) !important;
    height: 68px !important;
    border: 1px solid #00000029 !important;
    border-radius: 8px !important;
}
.wfull{
    width: 110px !important;
    height: 72px !important;
}
.line{
    border: 1px solid #00000029;
    width: 100%;
    height: 0;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
}
.text-10{
    font-size: 10px !important;
}
</style>
    